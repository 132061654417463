import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://api-ims.hamro.academy/api"
// {{main_url}}/tax-calculator/?gender=f&marital_status=1&differently_abled=0&income=100000&salary_type=m&festival_bonous=&allowance=&other=0&pf=&cit&ssf&life_insurance
export interface CalculatorInputsTypes {
    gender: string,
    marital_status: string,
    differently_abled: string;
    income: string;
    salary: string;
    salary_type: string;
    festival_bonous: string;
    allowance: string;
    other: string;
    pf: string;
    cit: string;
    ssf: string;
    life_insurance: string;
};

 export  interface ApiResponse {
    annual_gross_salary: number;
    taxable_income: number;
    net_payable_tax: number;
    your_tax_slab_is: Yourtaxslabi[];
    net_tax_liability_yearly: number;
    net_tax_liability_monthly: number;
  }
  
  interface Yourtaxslabi {
    'Taxable Salary': string;
    'Taxable Amount': number;
    'Tax Rate': string;
    'Tax Liability': number;
  }


export const calculatorApi = createApi({
    reducerPath: "calculatorApi",
    tagTypes: ["users"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseUrl}`, 
    },
    ),
    endpoints: (builder) => ({
        calculateTax: builder.mutation<ApiResponse, CalculatorInputsTypes>({
            query: (contact) => ({
                url: "/tax-calculator/",
                method: "POST",
                body: contact,
            }),
            invalidatesTags: ["users"],
        }),
    })
})

export const {
useCalculateTaxMutation  
} = calculatorApi;