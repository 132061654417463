import React, { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { BadapatraData } from '../typings';


interface SearchInputProps {
    searchText: string;
    setSearchText: (data:string) => void;
}


const SearchInput = ({searchText,setSearchText}:SearchInputProps) => {
   
    return (
        <div className="searchBox">
            <div className="searchWrapper">
                <input value={searchText} onChange={(e) => setSearchText(e.target.value)}  className="searchInput" type="text" name="" placeholder="Search" />
                <button className="searchButton">
                    <FiSearch />
                </button>
            </div>

        </div>
    )
}

export default SearchInput