import React from "react";
function Gallery() {
  return (
    <div className="bg-dark py-4" id="gallery">
      <div className="container my-3">
        <h2 className="text-center text-white mb-3">Our Gallery</h2>
        <div className="owl-carousel owl-theme">
          <div className="item">
            <div className="card">
              <img
                className="card-img-top"
                src="./images/banner/banner1.png"
                alt="Card"
              />
              <div className="card-body">
                <h6 className="card-title">This is amazing event.</h6>
                <p className="card-text">
                  Some example text some example text. John Doe is an architect
                  and engineer
                </p>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="card">
              <img
                className="card-img-top"
                src="./images/banner/banner2.png"
                alt="Card"
              />
              <div className="card-body">
                <h6 className="card-title">This is amazing event.</h6>
                <p className="card-text">
                  Some example text some example text. John Doe is an architect
                  and engineer
                </p>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="card">
              <img
                className="card-img-top"
                src="./images/banner/banner2.jpg"
                alt="Card"
              />
              <div className="card-body">
                <h6 className="card-title">This is amazing event.</h6>
                <p className="card-text">
                  Some example text some example text. John Doe is an architect
                  and engineer
                </p>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="card">
              <img
                className="card-img-top"
                src="./images/banner/banner3.jpg"
                alt="Card"
              />
              <div className="card-body">
                <h6 className="card-title">This is amazing event.</h6>
                <p className="card-text">
                  Some example text some example text. John Doe is an architect
                  and engineer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
