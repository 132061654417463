import React from "react";
import { NavLink } from "react-router-dom";


const Header = () => {
  return (
    <div>
      <header className="pt-4 pb-3 text-white" id="main-header">
        <div className="container d-flex flex-wrap justify-content-center">
          <div className="row align-items-center w-100">
            <div className="col-6 col-md-3 order-md-0 order-0">
              <a
                href="/"
                className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none"
              >
                <img
                  src="/images/logo/Nepal_Government_Logo.png"
                  className="img-fluid"
                  alt="Government of Nepal"
                  title="Government of Nepal"
                  width="60%"
                />
              </a>
            </div>

            <div className="col-12 col-md-6 text-center order-md-1 order-2">
              <h3 className="fw-bold mb-3">
                फेदीखोला गाउँपालिका, गाउँ कार्यपालिकाको कार्यालय
              </h3>
              <h4 className="mb-3">गण्डकी प्रदेश नेपालल</h4>
              <p className="mb-0" style={{ fontSize: "0.92rem" }}>
                सन्तुलित वातावरण सुशासन सहितकाे समाजवाद उन्मुख समृद्ध नगर !
                कृषि, पर्यटन तथा प्रविधिमा आधारित अर्थतन्त्र विकासको आधार
              </p>
            </div>

            <div className="col-6 col-md-3  order-md-2 order-1">
              <a
                href="/"
                className="d-flex align-items-center justify-content-end mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none"
              >
                <img
                  src="/images/logo/Phedi_Khola_Municipality_Logo.png"
                  className="img-fluid"
                  alt="Phedi Khola Municipality - Government of Nepal"
                  title="Phedi Khola Municipality - Government of Nepal"
                  width="60%"
                />
              </a>
            </div>
          </div>

          <div className="container container d-flex justify-content-between mt-2 d-none d-lg-flex">
            <ul className="nav align-items-center py-2 ">
              <li className="nav-item">
                <a
                  href="/"
                  className="badge rounded-pill  text-bg-secondary nav-link px-2 me-2 active"
                  aria-current="page"
                >
                  डिजिटल प्रोफाइल
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  className="badge rounded-pill  text-bg-secondary nav-link px-2 me-2"
                >
                  अनलाईन कर
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  className="badge rounded-pill  text-bg-secondary nav-link px-2 me-2"
                >
                  गुनासो दर्ता
                </a>
              </li>
              
              <li className="nav-item">
                
                <NavLink
                  to={"/citizencharter"}
                  className="badge rounded-pill  text-bg-secondary nav-link px-2 me-2"
                >
                  <span>सिटिजन चार्टर</span>
                </NavLink>
              </li>

              <li className="nav-item">
                
                <NavLink
                  to={"/taxcalculator"}
                  className="badge rounded-pill  text-bg-secondary nav-link px-2 me-2"
                >
                  <span>ट्याक्स क्यालकुलेटर</span>
                </NavLink>
              </li>
          
            </ul>

            <div>
              {/* <div
                className="input-group w-auto align-items-center"
                id="search-banner"
              >
                <input
                  className="form-control border-end-0 border"
                  id="example-search-input"
                  placeholder="Search..."
                />
                <span className="input-group-append">
                  <button
                    className="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5"
                    type="button"
                  >
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                      >
                        <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
                      </svg>
                    </i>
                  </button>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg bg-body-tertiary" id="main-nav">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link active text-dark"
                  aria-current="page"
                  href="/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 576 512"
                    width="25px"
                    height="25px"
                  >
                    <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                  </svg>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Simple One
                </a>
              </li>

           
             
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Department One
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Department Two
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Department Three
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle login-btn-from-menu"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "#1954a8",
                    padding: "0.3rem 1rem",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  Login
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://admin-ims.uptechsys.com"
                    >
                      Admin
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://yojana-ims.uptechsys.com/login"
                    >
                      Yojana
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://darta-ims.uptechsys.com/login"
                    >
                      Darta/Chalani
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://beruju-ims.uptechsys.com/beruju/"
                    >
                      Beruju
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            {/* <button className="btn btn-primary ms-auto shine">
              Employee Login
            </button> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
