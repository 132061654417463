import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import { calculatorApi } from "./features/users/calculatorApi";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(calculatorApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export default store;
