import React from "react";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Services from "../components/Services";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Team from "../components/Team";

function HomePage() {
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Team />
      <Services />
      <Gallery />
      <Footer />
    </React.Fragment>
  );
}

export default HomePage;
