import { useEffect, useRef, useState } from 'react'
import "../styles/css/pages/citizencharter.css"
import Header from "../components/Header"
import TableScrolling from '../sdk/TableScrolling'
import { api } from '../config/api'
import { BadapatraData, NabikaranData } from '../typings'
import parse from "html-react-parser"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SearchInput from '../components/SearchInput'



const CitizenCharter = () => {
    const scrollRef1 = useRef<HTMLDivElement>(null);
    const scrollRef2 = useRef<HTMLDivElement>(null);
    const [badapatraData, setBadapatraData] = useState<BadapatraData[] | []>([]);
    const [nabikaranData, setNabikaranData] = useState<NabikaranData[] | []>([]);
    const [searchText, setSearchText] = useState<string>("");



    const getBadapatraData = async () => {
        const res = await api.get("/public-nagarik-badda-patra/");
        setBadapatraData(res.data)
    }


    const getNabikaranData = async () => {
        const res = await api.get("public-dartaa-nabikaran-info/");
        setNabikaranData(res.data)
    }
    useEffect(() => {
        getBadapatraData();
        getNabikaranData();
    }, [])

    useEffect(() => {
        setBadapatraData(badapatraData.filter(el => el.type_of_service.toLowerCase().includes(searchText.toLowerCase())))
        setNabikaranData(nabikaranData.filter(el => el.sewaa.toLowerCase().includes(searchText.toLowerCase())))
        if (searchText === "" || searchText === null || searchText === undefined) {
            getBadapatraData();
            getNabikaranData();
        }

    }, [searchText])


    return (
        <>
            <Header />
            <div className='citizencharter'>
                <Tabs>
                    <TabList>
                        <Tab>नागरिक बडापत्र</Tab>
                        <Tab>कर/दर्ता/बार्षिक नबिकरण</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="tableWrapper">
                            <div>
                                <SearchInput searchText={searchText} setSearchText={setSearchText} />
                                <h1>नागरिक बडापत्र</h1>

                            </div>
                            <TableScrolling
                                className={"nagarik__badapatra"}
                                scrollRef={scrollRef1}
                                component={(
                                    <table style={{ overflowX: "auto" }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan={2}>s.No</th>
                                                <th rowSpan={2}>Service Type</th>
                                                <th rowSpan={2}>Service Receiving Procedure</th>
                                                <th rowSpan={1} colSpan={2}>
                                                    Service Fulfillment
                                                </th>
                                                <th rowSpan={2} >Service Provider</th>
                                                <th rowSpan={2}>Service Complaints Employee</th>
                                            </tr>
                                            <tr >
                                                <th>Cost</th>
                                                <th>Duration</th>
                                            </tr>

                                        </thead>

                                        <tbody
                                            className='table-body'>
                                            {badapatraData.map((charter, i) => (
                                                <tr key={charter.id}>
                                                    <td>
                                                        <div className='td__data'>
                                                        {i + 1}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {charter.type_of_service}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {parse(charter.required_paper)}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {charter.service_charge}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {charter.service_timing}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {charter.service_provider}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className='td__data'>
                                                        {charter.complainer}
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="tableWrapper">
                            <div>
                                <SearchInput searchText={searchText} setSearchText={setSearchText} />
                                <h1>कर/दर्ता/बार्षिक नबिकरण</h1>
                            </div>
                            <TableScrolling
                                scrollRef={scrollRef2}
                                className={"barsik__nabrikaran"}
                                component={(
                                    <table style={{ overflowX: "auto" }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan={2}>s.No</th>
                                                <th rowSpan={2}>Service Name</th>
                                                <th rowSpan={2}>Registration Amount</th>
                                                <th rowSpan={2}>Renewal Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            className='table-body'>
                                            {nabikaranData.map((item, i) => (
                                                <tr key={item.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.sewaa}</td>
                                                    <td>{item.dartaa}</td>
                                                    <td>{item.nabikaran}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            />
                        </div>

                    </TabPanel>
                </Tabs>



            </div>
        </>
    )
}

const serviceRenewalData = [
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },

    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },
    {
        id: 1,
        service_name: "Awesome Sewa",
        registration_number: "200",
        renewal_amount: "500"
    },

]

const citizenCharterData = [
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },
    {
        id: 1,
        serviceType: "Citizenship",
        service_receiving_procedure: "Go to ward office Fill up required papers",
        cost: "only 10 rupees per person",
        duration: "upto 1-2 months",
        service_provider: "Ward Office",
        Service_complaints_employee: "Mun Chief"
    },
    {
        id: 2,
        serviceType: "any kind",
        service_receiving_procedure: "okay done provided",
        cost: "free of cost",
        duration: "within a minute or an hou",
        service_provider: "ward president",
        Service_complaints_employee: "resposible person"
    },

]

export default CitizenCharter