import React from "react";

const Banner = () => {
  return (
    <div className="container container-sm">
      <div className="row py-3">
        <div className="col-12 col-md-6 col-lg-8">
          <div id="munCarousel" className="carousel slide">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#munCarousel"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#munCarousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#munCarousel"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="/images/banner/banner1.png"
                  className="d-block w-100"
                  alt="Banner logo"
                />
                <div className="carousel-caption d-none d-md-block text-left bg-dark w-100">
                  <h5>
                    This is a big banner title. This banner is used to show
                    feature article on our site with slider. This heading is the
                    title of featured news.
                  </h5>
                </div>
              </div>

              <div className="carousel-item">
                <img
                  src="/images/banner/banner2.png"
                  className="d-block w-100"
                  alt="Banner logo"
                />
                <div className="carousel-caption d-none d-md-block text-left bg-dark w-100">
                  <h5>
                    This is a big banner title. This banner is used to show
                    feature article on our site with slider. This heading is the
                    title of featured news.
                  </h5>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#munCarousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#munCarousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <div id="banner-news">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#home"
                >
                  Notices
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                  News
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#menu2">
                  Publications
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div id="home" className="container tab-pane active">
                <ul className="list-group">
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                </ul>
              </div>
              <div id="menu1" className="container tab-pane fade">
                <ul className="list-group">
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                </ul>
              </div>
              <div id="menu2" className="container tab-pane fade">
                <ul className="list-group">
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above.
                  </li>
                  <li className="list-group-item">
                    This is important notice number one. But it is little longer
                    than above. And this is multiline news as well.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
