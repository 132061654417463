import React from 'react'
import "../styles/css/modal.css"
import {ImCancelCircle} from "react-icons/im"

interface Props {
    modalOpen: boolean;
    setModalOpen: (modalOpen: boolean) => void;
}

const Modal = ({modalOpen,setModalOpen}:Props) => {
    return (
        <div className="modal__wrapper centered"  style={{display: modalOpen ? "block": "none", position: "fixed", top: 0, left: 0}} >
            <div className="modal__container">

                <div className="modal__upper centered-between">
                <div className="modal__heading">
                    <h5>Income Tax Rates Of Nepal 2079-80</h5>
                    <p>New Income Tax Rates For Individuals 2079-80 </p>
                </div>
                <ImCancelCircle onClick={() => setModalOpen(false)} className='cancel__btn' />
                </div>

                <table>
                    <tr>
                        <th>Particulars</th>
                        <th>Taxable Amount</th>
                        <th>Year 2079-80(New Tax Rate)</th>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr> <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>

                </table>

                <table>
                    <tr>
                        <th>Particulars</th>
                        <th>Taxable Amount</th>
                        <th>Year 2079-80(New Tax Rate)</th>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr> <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>First Tax Slab (Yearly Income up to 5,00,000)</td>
                        <td>5,00,000</td>
                        <td>1%</td>
                    </tr>

                </table>
            </div>
        </div>
    )
}

export default Modal