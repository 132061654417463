import React from "react";

const Services = () => {
  return (
    <div className="bg-secondary-subtle py-5" id="departments">
      <div className="container">
        <h3 className="text-center mb-3">Civil Area</h3>

        <div className="row">
          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">वडाहरु</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">सेवाहरु</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">विभागहरु</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">योजना व्यवस्थापन</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">नागरिक बडापत्र</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">ई-हाजिरी</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">घर नक्सा पास</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">ई-सरकारी सेवाहरू</h4>
                </div>
              </a>
            </div>
          </div>

          <div className="col-12 col-xs-2 col-sm-6 col-lg-4">
            <div className="card mb-3">
              <a href="/">
                <div className="card-body mb-0 pb-2 text-center">
                  <img
                    src="https://bheemdattamun.gov.np/online/assets/images/logo.png"
                    alt=""
                    className="img-fluid mb-3"
                  />
                  <h4 className="card-title">गुनासो</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
