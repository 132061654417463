import React from "react";

const Team = () => {
  return (
    <div id="team">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-3 shine">
              <img
                src="./images/teams/Ghanshyam_Subedi.png"
                alt="Ghanshyam Subedi"
              />
              <h4 className="name">घनश्याम सुवेदी</h4>
              <h5 className="title">अध्यक्ष</h5>
              <h6 className="contact">9856066601</h6>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-3 shine">
              <img
                src="./images/teams/Deu_Kumari_Gurung.jpeg"
                alt="Deu Kumari Gurung"
              />
              <h4 className="name">देउकुमारी गुरु‌ग</h4>
              <h5 className="title">उपाध्यक्ष</h5>
              <h6 className="contact">9856066602</h6>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-3 shine">
              <img
                src="./images/teams/Bishnu_Prasad_Paudel.jpg"
                alt="Bishnu Prasad Paudel"
              />
              <h4 className="name">विष्णुप्रसाद पौडेल</h4>
              <h5 className="title">प्रमुख प्रशासकीय अधिकृत</h5>
              <h6 className="contact">9856066603</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
