import React from "react";
import { Grid } from "@ims/common";

function Footer() {
  return (
    <React.Fragment>
      <div
        className="text-white"
        style={{ backgroundColor: "rgb(0, 65, 158)" }}
      >
        <div className="container">
          <footer className="py-5 pb-3">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <h4>फेदीखोला गाउँपालिका</h4>
                <p>कञ्चनपुर, सुदूरपश्चिम प्रदेश, नेपाल</p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                    width="18px"
                    className="me-2"
                  >
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                  phedikhola174@gmail.com
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 512 512"
                    width="18px"
                    className="me-2"
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  063-402053
                </p>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <h5>Map</h5>

                <iframe
                  title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d65135.45334248124!2d83.88572637870648!3d28.152142553574475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995c02a128d94c3%3A0x90989c32165b7df5!2sPhedi%20Khola!5e0!3m2!1sen!2snp!4v1680778823543!5m2!1sen!2snp"
                  width="100%"
                  height="120px"
                  loading="lazy"
                ></iframe>
              </div>

              <div className="col-12 col-md-12 col-lg-5 mb-3">
                <h5>केहि महत्वपूर्ण लिंकहरु</h5>
                <div className="d-flex justify-content-between">
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                  </ul>

                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <a href="/" className="nav-link p-0 text-white">
                        नेपाल सरकारको आधिकारिक पोर्टल
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="text-center border-top pt-2">
              <small>
                &copy; Phedi Khola Municipality {new Date().getFullYear()}.
                Powered by{" "}
                <a className="text-white" href="https://uptechsys.com/">
                  {" "}
                  Uptechsys.com
                </a>
              </small>
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
