import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import { AiOutlineInfoCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { BsCashCoin, BsBuildings } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { SubmitHandler, useForm } from "react-hook-form";

import "../styles/css/pages/taxcalculatepage.css"
import Modal from '../components/Modal';
import { ApiResponse, CalculatorInputsTypes, useCalculateTaxMutation } from '../rtk/features/users/calculatorApi';




const TaxCalculatePage = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm<CalculatorInputsTypes>();
    const gender = watch("gender")
    const [taxData,setTaxData] = useState<ApiResponse>();
    console.log(gender)
    const salary = watch("salary_type")
    const marital = watch("marital_status")
    console.log(marital)

    console.log(salary)

    const [calculateTax, { isError, isLoading, isSuccess, data: newData }] = useCalculateTaxMutation();
    const onSubmit: SubmitHandler<CalculatorInputsTypes> =  async (data) => {
        console.log(data)
        await  calculateTax(data)
    }
    useEffect(() => {
        
        setTaxData(newData)
    },[isSuccess])

    return (
        <>
            <div style={{ position: "relative" }}>
                <Header />
                <form onSubmit={handleSubmit(onSubmit)} className='tax__wrapper centered'>
                    <div className="tax__container centered-between">
                        <div className="tax__left">
                            <h5>Calculate your Personal Income TAX</h5>
                            <p>This tax calculator tool is designed as per the new salary tax which was announced during Budget Announcement of 2079/2080. </p>


                            {/* Gender */}
                            <div className="married__wrapper">
                                <label htmlFor="gender"><span>Gender</span></label>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <select {...register("gender")} className='select__input' name="gender" id="gender">
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* married */}
                            <div className="married__wrapper">
                                <label htmlFor="marital_status"><span>Marital Status</span></label>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        {/* <select {...register("marital_status")} className='select__input' name="marriedStatus" id="marriedStatus">
                                            <option value="married">Married</option>
                                            <option value="unmarried">Unmarried</option>
                                        </select> */}
                                         <select {...register("marital_status")} className='select__input' name="marital_status" id="marital_status">
                                            <option value="married">Married</option>
                                            <option value="unmarried">Unmarried</option>
                                        </select>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* option input */}
                            <div className="input__container">
                                <div className="checkbox__input">
                                    <label htmlFor="differently_abled"><span>Differently Abled</span></label>
                                    <input {...register("differently_abled")} type='checkbox' />
                                </div>
                            </div>

                            {/* income */}
                            <div className="income__wrapper">
                                <label htmlFor="income"><span>Income</span></label>
                                {/* option input */}
                                <div className="input__container">
                                    <div className="text__input">
                                        <div className="input__icon-wrapper">
                                            <BsCashCoin className='input__icon' />
                                        </div>
                                        <input {...register("salary")} type="text" placeholder='Salary' />
                                    </div>
                                    {/* <select {...register("salary_type")} className='select__input' name="salary_type" id="salary_type">
                                        <option value="yearly">Yearly</option>
                                        <option value="monthly">Monthly</option>
                                    </select> */}
                                    {/* <select {...register("salary_type")} className='select__input' name="salaryType" id="salaryType">
                                            <option value="married">Married</option>
                                            <option value="unmarried">Unmarried</option>
                                        </select> */}
                                         <select {...register("salary_type")} className='select__input' name="salary_type" id="salary_type">
                                            <option value="yearly">Yearly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    <div className="detail__icon-wrapper">
                                        <AiOutlineInfoCircle className='detail__icon' />
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <AiOutlinePlusCircle className='input__icon' />
                                            </div>
                                            <input {...register("festival_bonous")} type="text" placeholder='Festival Bonous' />
                                        </div>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <IoSettingsOutline className='input__icon' />
                                            </div>
                                            <input {...register("allowance")} type="text" placeholder='Allowance' />
                                        </div>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <IoSettingsOutline className='input__icon' />
                                            </div>
                                            <input {...register("other")} type="text" placeholder='Others' />
                                        </div>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            {/* deduction */}
                            <div className="deduction__wrapper">
                                <label htmlFor="marital status"><span>Deduction </span></label>
                                {/* option input */}
                                <div className="input__container">
                                    <div className="text__input">
                                        <div className="input__icon-wrapper">
                                            <BsCashCoin className='input__icon' />
                                        </div>
                                        <input {...register("pf")} type="text" placeholder='Provident Fund' />
                                    </div>
                                    <div className="detail__icon-wrapper">
                                        <AiOutlineInfoCircle className='detail__icon' />
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <BsBuildings className='input__icon' />
                                            </div>
                                            <input {...register("cit")} type="text" placeholder='Citizen Investment Trust' />
                                        </div>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <BiUser className='input__icon' />
                                            </div>
                                            <input {...register("ssf")} type="text" placeholder='Social Security Fund' />
                                        </div>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>

                                <div className="input__wrapper">
                                    {/* option input */}
                                    <div className="input__container">
                                        <div className="text__input">
                                            <div className="input__icon-wrapper">
                                                <BiUser className='input__icon' />
                                            </div>
                                            <input {...register("life_insurance")} type="text" placeholder='Life Insurance' />
                                        </div>

                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="buttons">
                                <button type='submit' className="calculate-btn">
                                    Calculate
                                </button>
                                <button className="reset-btn">
                                    Reset
                                </button>
                            </div>

                            <p className="note">
                                <strong>Note:</strong> This tool is made for general tax calculation only. Information from this tool should not be used for any other purpose.
                            </p>

                        </div>
                        <div className="tax__right">
                            <div className="tax__right-upper">
                                <div className="tax__card">
                                    <div className="tax__card-up">
                                        <h5>Annual Gross Salary </h5>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                    <div className="tax__card-down">
                                        <h1>{taxData?.annual_gross_salary || "0.00"}</h1>
                                    </div>
                                </div>

                                <div className="tax__card">
                                    <div className="tax__card-up">
                                        <h5>Taxable Income </h5>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                    <div className="tax__card-down">
                                        <h1>{taxData?.taxable_income || "0.00"}</h1>
                                    </div>
                                </div>

                                <div className="tax__card">
                                    <div className="tax__card-up">
                                        <h5>Net Payable Tax </h5>
                                        <div className="detail__icon-wrapper">
                                            <AiOutlineInfoCircle className='detail__icon' />
                                        </div>
                                    </div>
                                    <div className="tax__card-down">
                                        <h1>{taxData?.net_payable_tax || "0.00"}</h1>

                                    </div>
                                </div>
                            </div>

                            <div className="tax__right-lower">
                                <hr />
                                <table >
                                    <tr>
                                        <th>Taxable Salary</th>
                                        <th>Taxable Amount</th>
                                        <th>Tax Rate</th>
                                        <th>Tax Liability</th>
                                    </tr>

                                    {taxData?.your_tax_slab_is?.map(el => (
                                        <tr>
                                            <td>{el['Taxable Salary']}</td>
                                            <td>{el['Taxable Amount']}</td>
                                            <td>{el['Tax Rate']}</td>
                                            <td>{el['Tax Liability']}</td>
                                        </tr>
                                    ))

                                    }

                                </table>
                                <div className="tax__liability">
                                    <h5>Net Tax Liability (yearly)</h5>
                                    <p>{taxData?.net_tax_liability_yearly || "0.00"}</p>
                                </div>
                                <div className="tax__liability">
                                    <h5>Net Tax Liability (Monthly)</h5>
                                    <p>{taxData?.net_tax_liability_monthly || "0.00"}</p>
                                </div>
                                <div className="new__income__button centered">
                                    <button onClick={() => setModalOpen(true)}>New Income Tax Rates For (
                                        
                                    )</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </form>
                <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
        </>
    )

}

export default TaxCalculatePage