import React from "react";
import HomePage from "./pages/HomePage";
import "./styles/css/custom.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CitizenCharter from "./pages/CitizenCharter";
import TaxCalculatePage from "./pages/TaxCalculatePage";

function App() {
  return (
    <>
     <BrowserRouter>
     <Routes>
          <Route index element={<HomePage />} />
          <Route path="citizencharter" element={<CitizenCharter />} />
          <Route path="taxcalculator" element={<TaxCalculatePage />} />
     </Routes>
     </BrowserRouter>
    </>
  )
}

export default App;
