import { combineReducers } from "@reduxjs/toolkit";
import { calculatorApi } from "./features/users/calculatorApi";



const rootReducer = combineReducers({
  [calculatorApi.reducerPath]: calculatorApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
